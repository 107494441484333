import type { Component } from "vue";

import type { LocaleIso, LocaleMorpheus } from "@/lib/helpers/locales";
import type { VueIslandPlugins } from "@/lib/helpers/vueIsland";

type VueIslandConfig = Record<string, () => Promise<Component>>;

function initVueIslands(
  components: VueIslandConfig,
  plugins: VueIslandPlugins = {},
  locale?: LocaleIso | LocaleMorpheus,
) {
  renderVueIslands(components, plugins, locale);
  const observer = new MutationObserver(() => {
    renderVueIslands(components, plugins, locale);
  });
  observer.observe(document.body, { childList: true, subtree: true });
}

function renderVueIslands(
  components: VueIslandConfig,
  plugins: VueIslandPlugins = {},
  locale?: LocaleIso | LocaleMorpheus,
) {
  // We extract the name here so we can remove the attribute before we do something async
  // This prevents trying to render twice on the same container
  document
    .querySelectorAll<HTMLElement>("[data-component]")
    .forEach((container) => {
      const name = container.dataset.component!;
      container.removeAttribute("data-component");

      const observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry?.isIntersecting) {
            observer.disconnect();
            const { renderVueIsland } = await import("@/lib/helpers/vueIsland");

            renderVueIsland(name, container, components[name], plugins, locale);
          }
        },
        { rootMargin: "200px" },
      );
      observer.observe(container);
    });
}

export { initVueIslands };
export type { VueIslandConfig };
